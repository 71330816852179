import Constant from '../common/Constant';

export const applicationDefaultValues = {
  [Constant.ACKNOWLEDGE_SOP_IND]: true,
  [Constant.ACKNOWLEDGE_OCA_IND]: false,
  [Constant.ACKNOWLEDGE_HEGPIC_IND]: true,
  [Constant.REAPPLY]: false,
  [Constant.PREV_ASSIGNED_ORG_CODE]: 0,
  [Constant.ORG_PHONE_EXT]: '',
  [Constant.PHYSICAL_ADDRESS2]: '',
  [Constant.PHYSICAL_ZIP4]: '',
  [Constant.WEBADDRESS]: '',
  [Constant.CLASS_TYPE_OTHER_NAME]: '',
  [Constant.MULTI_STATES_CLASS_PROVD]: false,
  [Constant.MULTI_STATE_TERRITORY_LIST]: [],
  [Constant.PARTICIPANT_DELIVERY_MODE_IDS]: [],
  [Constant.NATIONAL_ONLINE_PROVIDER]: false,
  [Constant.IS_ADDITIONAL_CONTENT_FILE]: '',
  [Constant.ADDRESS_VALIDATED_IND]: false,
  [Constant.INCLUDE_SECONDARY_CONTACT]: true,
  [Constant.INCLUDE_DATA_PREPARER]: true,
  [Constant.DATA_PREPARER_TYPE]: Constant.DATA_PREPARER_NEW_CONTACT_TXT,
  [Constant.DATA_PREPARER_AFFLICATION]: '',
  [Constant.IS_CURRICULUM]: 'false', //when set to bool as false, we get error on submit, set to string as false for now until we find solution
  [Constant.FILE_SIZE_MB]: 0,
  [Constant.OTHER_CURRICULUM_IND]: false,
  [Constant.IS_OTHER_CURRICULUM_OPTION_ID]: false,
  [Constant.SUPPLEMENTAL_FILE_OVERSIZE]: false,
  [Constant.CURRICULUM_FILE_OVER_SIZE]: false,
  [Constant.OTHER_CURRICULUM_IND]: false,
  [Constant.SUPLLEMENTAL_FILES_IND]: false,
  [Constant.SUPPLEMENT_FILE_ID]: [],
  [Constant.DELIVERY_MODE_IN_PERSON]: 'false',
  [Constant.DELIVERY_MODE_ONLINE]: 'false',
  [Constant.DELIVERY_MODE_ACCESS_PASSED]: 'false',
  [Constant.INTERACTION_ID]: null,
  [Constant.INTERACTION_OTHER]: '',
  [Constant.TECHNOLOGY_ID]: null,
  [Constant.TECHNOLOGY_OTHER]: '',
  [Constant.INCLUDE_PROJECTED_START_DATE]: '',
  [Constant.DELIVERY_ATTESTATION]: false,
  //below here is all the optional field
  [Constant.CONTACT_PHONE_EXT]: '',
  [Constant.CONTACT_SALUTATION]: '',
  [Constant.CONTACT_MID_NAME]: '',
  [Constant.SECONDARY_CONTACT_SALUTATION]: '',
  [Constant.SECONDARY_CONTACT_TITLE]: null,
  [Constant.SECONDARY_CONTACT_TITLE_OTHER]: '',
  [Constant.SECONDARY_CONTACT_FIRST_NAME]: '',
  [Constant.SECONDARY_CONTACT_MID_NAME]: '',
  [Constant.SECONDARY_CONTACT_LAST_NAME]: '',
  [Constant.SECONDARY_CONTACT_SALUTATION]: '',
  [Constant.SECONDARY_CONTACT_PHONE]: '',
  [Constant.SECONDARY_CONTACT_PHONE_AREA_CODE]: '',
  [Constant.SECONDARY_CONTACT_PHONE3]: '',
  [Constant.SECONDARY_CONTACT_PHONE4]: '',
  [Constant.SECONDARY_CONTACT_PHONE_EXT]: '',
  [Constant.SECONDARY_CONTACT_CONTACT_EMAIL]: '',
  [Constant.DATA_PREPARER_SALUTATION]: '',
  [Constant.DATA_PREPARER_TITLE]: null,
  [Constant.DATA_PREPARER_FIRST_NAME]: '',
  [Constant.DATA_PREPARER_MID_NAME]: '',
  [Constant.DATA_PREPARER_LAST_NAME]: '',
  [Constant.DATA_PREPARER_SALUTATION]: '',
  [Constant.DATA_PREPARER_PHONE_AREA_CODE]: '',
  [Constant.DATA_PREPARER_PHONE3]: '',
  [Constant.DATA_PREPARER_PHONE4]: '',
  [Constant.DATA_PREPARER_PHONE_EXT]: '',
  [Constant.DATA_PREPARER_EMAIL]: '',
  [Constant.ENROLLMENT_LOCATION]: '',
  [Constant.SOCIAL_VULNERABILITY_INDEX]: false,
  [Constant.PROJECTED_START_DATE]: null,
  [Constant.ORG_ADA_TYPE]: false,
  [Constant.ORG_ADCES_TYPE]: false,
  [Constant.ORG_EMAIL]: '',
};
