import React from "react";
import Constant from "../../common/Constant";
import RequiredSpanText from "../../common/RequiredSpanText.jsx";

const BeforeYouBegin = ({
    applicationObj,
    onCheckBoxChange,
    errors }) => {
    return (
        <>
            <span>
                <span className="text-red">*</span>
                Indicates Required Field
            </span>
            <div className="card mb-4">
                <h2 className="h4 card-header bg-primary">
                    Before You Begin
                </h2>
                <div className="card-body">
                    <h3 class="h5 font-weight-header mb-2">Please acknowledge the following before submitting your application.<RequiredSpanText/></h3>
                    {/* <div className="custom-control">
                        <input
                            type="checkbox"
                            className="big-checkbox"
                            onChange={onCheckBoxChange}
                            name={Constant.ACKNOWLEDGE_SOP_IND}
                            value={applicationObj[`${Constant.ACKNOWLEDGE_SOP_IND}`]}
                            id={Constant.ACKNOWLEDGE_SOP_IND}
                        />
                        <label
                            className="pl-3"
                            htmlFor={Constant.ACKNOWLEDGE_SOP_IND}
                        >
                            I have read and understand the <a href="https://nationaldppcsc.cdc.gov/s/article/DPRP-Standards-and-Operating-Procedures" target="_blank" rel="noopener noreferrer">DPRP Standards and Operating Procedures</a>.
                        </label>
                        {errors[`${Constant.ACKNOWLEDGE_SOP_IND}`] && (
                            <div className={`alert alert-danger col-8`}>
                                {errors[`${Constant.ACKNOWLEDGE_SOP_IND}`]}
                            </div>
                        )}
                    </div> */}
                    <div className="custom-control">
                        <input
                            type="checkbox"
                            className="big-checkbox"
                            onChange={onCheckBoxChange}
                            name={Constant.ACKNOWLEDGE_OCA_IND}
                            value={applicationObj[`${Constant.ACKNOWLEDGE_OCA_IND}`]}
                            id={Constant.ACKNOWLEDGE_OCA_IND}
                        />
                        <label
                            className="pl-3"
                            htmlFor={Constant.ACKNOWLEDGE_OCA_IND}
                        >
                            My organization has taken the <a target="_blank" href="https://nccdphp.my.salesforce.com/sfc/p/#t0000000TZNF/a/3d000000CBw2/wEGSdCqOXcziZLPGRZtqCV3io.Qyge7kqVAYVYWz9hI" rel="noopener noreferrer">Organizational Capacity Assessment</a>
                            &nbsp; and is ready to apply for CDC recognition.
                        </label>
                        {errors[`${Constant.ACKNOWLEDGE_OCA_IND}`] && (
                            <div className={`alert alert-danger col-8`}>
                                {errors[`${Constant.ACKNOWLEDGE_OCA_IND}`]}
                            </div>
                        )}
                    </div>
                    {/* <div className="custom-control">
                        <input
                            type="checkbox"
                            className="big-checkbox"
                            onChange={onCheckBoxChange}
                            name={Constant.ACKNOWLEDGE_HEGPIC_IND}
                            value={applicationObj[`${Constant.ACKNOWLEDGE_HEGPIC_IND}`]}
                            id={Constant.ACKNOWLEDGE_HEGPIC_IND}
                        />
                        <label
                            className="pl-3"
                            htmlFor={Constant.ACKNOWLEDGE_HEGPIC_IND}
                        >
                            Our organization staff has read and understands the Health Equity Guiding Principles for
                            Inclusive Communication
                            <a target="_blank" rel="noreferrer" className="ml-1" href="https://www.cdc.gov/healthcommunication/Health_Equity.html">
                                (Health Equity Guiding Principles for Inclusive Communication | Gateway to Health Communication | CDC)
                            </a>.
                            We will apply these principles in our communications to the best of our ability.
                        </label>
                        {errors[`${Constant.ACKNOWLEDGE_HEGPIC_IND}`] && (
                            <div className={`alert alert-danger col-8`}>
                                {errors[`${Constant.ACKNOWLEDGE_HEGPIC_IND}`]}
                            </div>
                        )}
                    </div> */}
                    <p className="ml-3 mt-2">
                    <small>
                        NOTE: If you would like to review your Capacity Assessment results with a member of the Technical Assistance Team,
                        please submit that request by clicking on “Contact Us” on the <a target="_blank" href="https://nationaldppcsc.cdc.gov/s/" rel="noopener noreferrer">
                        National Diabetes Prevention Program Customer Service Center (National DPP CSC) website</a>.
                    </small>
                    </p>
                </div>
            </div>
        </>
    );
};

export default BeforeYouBegin;
