import React from 'react';
import RequiredSpanText from '../../common/RequiredSpanText';

function OrganizationCategories({
  organizationCategories,
  loadingApiStatus,
  onChange,
  name,
  defaultOption = 'Select one...',
  value,
  error,
}) {
  return (
    <div>
      {loadingApiStatus ? (
        <h2>
          <strong>Loading State Data....</strong>
        </h2>
      ) : (
        <>
          <h3 className="h5 font-weight-header">
            <label htmlFor={name}>
              <RequiredSpanText text="Organization Type" />
            </label>
          </h3>
          <div>
            <p className="mb-2">
              <small className="ml-3">
                Choose the option that best describes the organization type.
              </small>
            </p>
          </div>
          <div className="form-group mb-0">
            <select
              className="form-control col-6 ml-3"
              id={name}
              onChange={onChange}
              value={value}
              name={name}
            >
              <option>{defaultOption}</option>
              {organizationCategories.map((lookUpItem) => {
                return (
                  <option key={lookUpItem.id} value={lookUpItem.id}>
                    {lookUpItem.name}
                  </option>
                );
              })}
            </select>
            {error && (
              <div className={`alert alert-danger col-6 ml-3`}>{error}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default OrganizationCategories;
