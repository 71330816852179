import React from "react";
import Constant from "../../common/Constant";
import TextInput from "../../common/TextInput.jsx";
import RequiredSpanText from "../../common/RequiredSpanText.jsx";

const CertificationOfApplication = ({
  onChange,
  onValidateBlur,
  calendarDate,
  applicationObj,
  errors
}) => {
  return (
    <div className="card">
      <h2 className="h4 card-header bg-primary">Certification of Application</h2>
      <div className="card-body">
        <h3 className="h5 font-weight-header">Electronic Signature</h3>
        <p className="ml-3 mt-1 mb-1">
          <small>
            {Constant.CERTIFICATION_OF_APPLICATION_TEXT}
          </small>
        </p>
        <div className="ml-3">
          <TextInput
            onChange={onChange}
            onValidateBlur={onValidateBlur}
            value={applicationObj[`${Constant.ELECTRONIC_SIG_NAME}`] || ""}
            labelClassName=""
            labelText="Name of Authorized Representative"
            name={Constant.ELECTRONIC_SIG_NAME}
            inputClassName="col-4"
            containerClassName="ml-3 mt-2"
            maxLength="100"
            requiredLabel={true}
            error={errors && errors[`${Constant.ELECTRONIC_SIG_NAME}`]}
            errorClassName="col-4"
            validateMsg={Constant.ELECTRONIC_SIG_NAME_VALIDATE_MSG}
          />
          <TextInput
            onChange={onChange}
            onValidateBlur={onValidateBlur}
            value={applicationObj[`${Constant.ELECTRONIC_SIG_TITLE}`] || ""}
            labelClassName=""
            labelText="Title of Authorized Representative"
            name={Constant.ELECTRONIC_SIG_TITLE}
            inputClassName="col-4"
            containerClassName="ml-3 mt-2"
            maxLength="50"
            requiredLabel={true}
            error={errors && errors[`${Constant.ELECTRONIC_SIG_TITLE}`]}
            errorClassName="col-4"
            validateMsg={Constant.ELECTRONIC_SIG_TITLE_VALIDATE_MSG}
          />
          <TextInput
            onChange={onChange}
            onValidateBlur={onValidateBlur}
            value={applicationObj[`${Constant.ELECTRONIC_ORG_NAME}`] || ""}
            labelClassName=""
            labelText="Organization Name"
            name={Constant.ELECTRONIC_ORG_NAME}
            inputClassName="col-4"
            containerClassName="ml-3 mt-2"
            maxLength="100"
            requiredLabel={true}
            error={errors && errors[`${Constant.ELECTRONIC_ORG_NAME}`]}
            errorClassName="col-4"
            validateMsg={Constant.ELECTRONIC_ORG_NAME_VALIDATE_MSG}
          />
          <p>
            <RequiredSpanText text="Today's Date" />
          </p>
          <label htmlFor="todaysDate" className="accessible-hide">
            Today's date
          </label>
          <input
            className="form-control"
            id="todaysDate"
            style={{ width: "170px" }}
            value={calendarDate}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default CertificationOfApplication;
