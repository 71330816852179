import React from 'react';
import Constant from '../../common/Constant';
import RadioInput from '../../common/RadioInput';
import RequiredSpanText from '../../common/RequiredSpanText';
import TextInput from '../../common/TextInput';
import { FileUpload } from './FileUpload';

function Curriculums({
  curriculum,
  loadingApiStatus,
  onChange,
  onChangeCurriculum,
  isCheckedOtherCurriculum,
  isUploadDisabled,
  applicationObj,
  totalFileSize,
  onChangeUpload,
  errors,
}) {
  return (
    <div>
      {loadingApiStatus ? (
        <h2>
          <strong>Loading Curriculums....</strong>
        </h2>
      ) : (
        <>
          <h3 className="h5 font-weight-header">
            <RequiredSpanText text="Curriculum" />
          </h3>
          <div
            className="ml-3"
            role="group"
            aria-label="Program Delivery Curriculum"
          >
            <p className="mb-0 my-2">Select only one</p>
            {curriculum
              .filter((lookUpItem) => lookUpItem.name.indexOf('2012') === -1)
              .map((lookUpItem, index) => {
                return (
                  <div key={lookUpItem.name} className="ml-4">
                    <input
                      type="radio"
                      className="mr-2"
                      name={Constant.SUBMITTED_CURRICULUM_ID}
                      onChange={onChangeCurriculum}
                      value={lookUpItem.id}
                      data-curriculumname={lookUpItem.name}
                      id={lookUpItem.id + 'Curriculum'}
                      defaultChecked={index === 0}
                      aria-checked={
                        applicationObj[
                          `${Constant.SUBMITTED_CURRICULUM_ID}`
                        ] === lookUpItem.id
                      }
                    />
                    <label className="" htmlFor={lookUpItem.id + 'Curriculum'}>
                      {lookUpItem.name}
                    </label>
                  </div>
                );
              })}
            <div className="mt-2">
              {applicationObj[`${Constant.CURRICULUM}`] !== '9999' &&
                applicationObj[`${Constant.CURRICULUM}`] !== '' && (
                  <RadioInput
                    onChange={onChange}
                    name={Constant.IS_ADDITIONAL_CONTENT_FILE}
                    valueOne={true}
                    valueTwo={false}
                    containerClassName="ml-3"
                    introText={
                      'Do modules include additional content outside the CDC-approved curriculum ' +
                      '(i.e videos, webinars, resources, recipes, articles)?'
                    }
                    isRequired={true}
                    defaultValueTwo={true}
                    ariaSelected={''}
                    error={errors[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`]}
                    ariaGroupLabel={
                      'Do modules include additional content outside the CDC-approved curriculum ' +
                      '(i.e videos, webinars, resources, recipes, articles)?'
                    }
                  />
                )}
            </div>
            {(applicationObj[`${Constant.IS_ADDITIONAL_CONTENT_FILE}`] ===
              true ||
              isCheckedOtherCurriculum) && (
              <div>
                {isCheckedOtherCurriculum && (
                  <p className="ml-4">
                    NOTE: If you select Other Curriculum, you must submit your
                    curriculum files.
                  </p>
                )}
                <p className="ml-4">
                  {isCheckedOtherCurriculum
                    ? 'Other Curriculum '
                    : 'Additional '}
                  content must be reviewed and approved by CDC before use.
                  Please upload your{' '}
                  {isCheckedOtherCurriculum
                    ? 'curriculum '
                    : 'additional content '}{' '}
                  below or to the{' '}
                  <a href="https://nationaldppcsc.cdc.gov/s/">
                    National DPP Customer Service Center
                  </a>{' '}
                  for a 4-6 week CDC review and approval process.
                </p>
                {isCheckedOtherCurriculum && (
                  <>
                    <TextInput
                      onChange={onChange}
                      value={applicationObj[Constant.CURRICULUM_NAME] || ''}
                      labelClassName=""
                      labelText="Curriculum Name"
                      name={Constant.CURRICULUM_NAME}
                      inputClassName="col-4"
                      containerClassName="ml-3 mt-2"
                      requiredLabel={true}
                      error={errors[`${Constant.CURRICULUM_NAME}`]}
                      errorClassName="col-4"
                      maxLength="100"
                      validateMsg={Constant.CURRICULUM_NAME_VALIDATE_MSG}
                    />
                    <div className="ml-3">
                      <label htmlFor="">
                        <RequiredSpanText text="Curriculum Description" />
                      </label>
                      <textarea
                        onChange={onChange}
                        name={Constant.CURRICULUM_DESCRIPTION}
                        value={
                          applicationObj[Constant.CURRICULUM_DESCRIPTION] || ''
                        }
                        className="form-control col-4"
                        id=""
                        rows="4"
                      />
                    </div>
                    {errors[`${Constant.CURRICULUM_DESCRIPTION}`] && (
                      <div className={`alert alert-danger col-4 ml-3`}>
                        {errors[`${Constant.CURRICULUM_DESCRIPTION}`]}
                      </div>
                    )}
                  </>
                )}
                <div className="ml-3">
                  <p>
                    Submit your{' '}
                    {isCheckedOtherCurriculum
                      ? 'Curriculum '
                      : 'Additional Content '}{' '}
                    Files
                  </p>
                  <small>
                    <ul>
                      <li>Multiple files can be uploaded.</li>
                      <li>
                        To upload more than one file follow these steps:
                        <ol>
                          <li>
                            Place your curriculum files into a single folder on
                            your computer’s hard drive where you can easily find
                            it.
                          </li>
                          <li>
                            Click the browse button in the file upload box
                            below.
                          </li>
                          <li>
                            In the dialog box that opens your file system,
                            select the folder you created.
                          </li>
                          <li>
                            Select all the files in the folder and click on
                            Open.
                          </li>
                          <li>
                            Confirm the correct number of files you selected in
                            the upload window.
                          </li>
                          <li>
                            Confirm the file names by moving your mouse over the
                            upload window and checking the list that appears.
                          </li>
                          <li>
                            Complete the rest of the form and submit your
                            application.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Allowed file types:{' '}
                        {isCheckedOtherCurriculum
                          ? 'doc, docx, xls, xlsx, pdf '
                          : ' doc, docx, ppt, pptx, pdf, jpg, mp4, mpeg, mov, and wmv'}
                        .
                      </li>
                      <li>Maximum length of file name: 200 characters.</li>
                      <li>Combined file size: 20MB.</li>
                      <li>Maximum number of files: 20.</li>
                    </ul>
                  </small>
                  <p className="mt-4 mb-1">
                    {isCheckedOtherCurriculum ? (
                      <RequiredSpanText text="Curriculum Files" />
                    ) : (
                      <RequiredSpanText text="Additional Content Files" />
                    )}
                  </p>
                  <div className="ml-4">
                    <FileUpload
                      applicationObj={applicationObj}
                      onChangeUpload={onChangeUpload}
                      totalFileSize={totalFileSize}
                      disabled={isUploadDisabled}
                    />
                  </div>
                  <div className="custom-control mt-3 font-weight-bold">
                    <input
                      type="checkbox"
                      tabindex="-1"
                      className="big-checkbox"
                      name={Constant.FILE_OVER_SIZE}
                      onChange={onChange}
                      value={
                        applicationObj[`${Constant.FILE_OVER_SIZE}`] || false
                      }
                    />
                    <label className="pl-3">
                      Check here if your{' '}
                      {isCheckedOtherCurriculum
                        ? 'curriculum'
                        : 'additional content'}{' '}
                      files exceed the total size limit of 20 megabytes for
                      submission within the DPRP Application form, or you are
                      experiencing difficulty uploading your files.
                    </label>
                  </div>
                  <div className="ml-4 mb-3 font-italic">
                    <small>
                      Please visit the{' '}
                      <a
                        href="https://nationaldppcsc.cdc.gov/s/"
                        title="National DPP Customer Service Center"
                      >
                        National DPP Customer Service Center
                      </a>{' '}
                      to upload your{' '}
                      {isCheckedOtherCurriculum
                        ? 'curriculum'
                        : 'additional content'}{' '}
                      files as case attachments within the next 10 working days.
                      If you have not previously registered with the National
                      DPP CSC, you will need to register on a one-time basis to
                      submit National DPP Technical Assistance Inquiries. To
                      register with the National DPP Customer Service Center,
                      please go to
                      <a
                        href="https://nationaldppcsc.cdc.gov/s/"
                        title="NationalDPPCSC.cdc.gov"
                      >
                        NationalDPPCSC.cdc.gov
                      </a>
                      , select “Login” and then “Register.” To request technical
                      assistance, please sign in and then select “Contact
                      Us/Contact Support".
                    </small>
                  </div>
                </div>
              </div>
            )}
            {errors[`${Constant.CURRICULUM}`] && (
              <div className={`alert alert-danger col-3`}>
                {errors[`${Constant.CURRICULUM}`]}
              </div>
            )}
            {errors[`${Constant.FILE_UPLOAD}`] && (
              <div className={`alert alert-danger col-5`}>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: errors[`${Constant.FILE_UPLOAD}`],
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Curriculums;
