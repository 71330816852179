import React from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu.jsx";
import { CDCFooter } from "@cdcent/templatepackage-react";
import { CDCSiteTitle } from "./CDCSiteTitle.jsx";
import { CDCHeader } from "./CDCHeader.jsx";
import { useLocation } from "react-router-dom";
import { isKey } from '../../common/Util.js';

export function Layout(props) {
  let location = useLocation();
  function handleNav(e, focusTarget) {
    if (isKey(e, 'Enter')) {
      focusOnTarget(focusTarget);
    }
  }
  function focusOnTarget(focusTarget) {
    const el = document.getElementById(focusTarget);
    el.scrollIntoView();
    el.focus();
  }

  return (
    <div>
      <header>
        <button
            className="skip-main"
            onKeyUp={(e) => handleNav(e, 'content')}
            onClick={() => focusOnTarget('content')}
          >
            skip to main content
          </button>
        <CDCHeader search={false} />
        <CDCSiteTitle
          title={
            location.pathname.toLocaleLowerCase().indexOf("/registry") === -1
              ? "Diabetes Prevention Recognition Program Application"
              : "Diabetes Prevention Recognition Program Registry"
          }
        />
      </header>
      <NavMenu />
      <Container>{props.children}</Container>
      <CDCFooter />
    </div>
  );
}
