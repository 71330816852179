import React from 'react';
import RoleTitles from '../components/ui/RoleTitles.jsx';
import Constant from './Constant';
import TextInput from './TextInput.jsx';

function ContactInfo({
  applicationObj,
  controlTitle,
  controlSecondaryTitle,
  controlSecondaryText,
  errors,
  isDataPreparer = false,
  isOtherSalutation,
  onChange,
  onKeyDown,
  onValidateBlur,
  onBlurPhoneNumber,
  onSalutationChange,
  emailAddressControl,
  emailAddress,
  emailAddressVerifyControl,
  emailAddressVerify,
  firstNameControl,
  firstName,
  midNameControl,
  midName,
  lastNameControl,
  lastName,
  applicantTitleControl,
  applicantTitle,
  phoneNumberControl,
  phoneNumber,
  phoneNumberAreaCode,
  phoneNumber3,
  phoneNumber4,
  PhoneExtControl,
  PhoneExt,
  primaryContactInfo = false,
  isSecondaryContact = false,
  salutationDdl,
  salutation,
  roleTitles = [],
  applicantTitleControlOther = '',
  applicantTitleOther = '',
}) {
  return (
    <>
      <fieldset className="form-group">
        <legend className="accessible-hide">
          {primaryContactInfo && 'Program Coordinator Name and Information'}
          {isSecondaryContact && 'Secondary Contact Name and Information'}
          {isDataPreparer && 'Data Preparer Name and Information'}
        </legend>
        {(!isDataPreparer ||
          (applicationObj[`${Constant.DATA_PREPARER_TYPE}`] ===
            Constant.DATA_PREPARER_NEW_CONTACT_TXT &&
            isDataPreparer)) && (
          <div>
            <h3 className="h5 font-weight-header">{controlTitle}</h3>
            <div className="row col-12 mb-3">
              <div className="col-3">
                <label htmlFor={salutationDdl} className="my-2">
                  Salutation
                </label>
                <span>&nbsp;</span>
                <select
                  id={salutationDdl}
                  className="form-control"
                  name={salutationDdl}
                  onChange={onChange}
                >
                  <option value="">Select One...</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="Other">Other (please specify)</option>
                </select>
              </div>
              {isOtherSalutation && (
                <div className="col-3">
                  <TextInput
                    onChange={onSalutationChange}
                    value={salutation || ''}
                    labelClassName=""
                    labelText="Other"
                    accessibleHideLabel={true}
                    extraText=""
                    name={salutationDdl}
                    inputClassName="mt-3"
                    containerClassName=""
                    maxLength="50"
                  />
                </div>
              )}
            </div>
            <div className="row col-12">
              <div className="mr-3 col-3">
                <TextInput
                  onChange={onChange}
                  onValidateBlur={onValidateBlur}
                  value={firstName || ''}
                  labelClassName=""
                  labelText="First"
                  extraText=""
                  name={firstNameControl}
                  inputClassName=""
                  containerClassName=""
                  maxLength="75"
                  requiredLabel={true}
                  error={errors && errors[`${firstNameControl}`]}
                  validateMsg={Constant.CONTACT_FIRST_NAME_VALIDATE_MSG}
                />
              </div>
              <div className="col-2">
                <TextInput
                  inputClassName="col-4"
                  labelClassName=""
                  labelText="Middle Initial"
                  maxLength="1"
                  name={midNameControl}
                  onChange={onChange}
                  value={midName || ''}
                />
              </div>
              <div className="mr-3 col-3">
                <TextInput
                  onChange={onChange}
                  onValidateBlur={onValidateBlur}
                  value={lastName || ''}
                  labelClassName=""
                  labelText="Last"
                  extraText=""
                  name={lastNameControl}
                  inputClassName=""
                  containerClassName=""
                  maxLength="75"
                  requiredLabel={true}
                  error={errors && errors[`${lastNameControl}`]}
                  validateMsg={Constant.CONTACT_LAST_NAME_VALIDATE_MSG}
                />
              </div>
            </div>
            <div className="row col-12">
                <div className="mr-3 col-11 mt-3">
                  <RoleTitles
                    roleTitles={roleTitles}
                    secondaryLabel={isDataPreparer ? Constant.TITLE_AFFILIATION_SECONDARY_LABEL : ''}
                    onChange={onChange}
                    name={applicantTitleControl}
                    value={applicantTitle || 0}
                    error={errors && errors[`${applicantTitleControl}`]}
                  />
                  {applicationObj[`${applicantTitleControl}`] === 14 && (
                    <TextInput
                      inputClassName={'col-3'}
                      onChange={onChange}
                      onValidateBlur={onValidateBlur}
                      errorClassName={'col-3'}
                      value={applicantTitleOther || ''}
                      name={applicantTitleControlOther}
                      accessibleHideLabel={true}
                      maxLength="50"
                      error={errors && errors[`${applicantTitleControlOther}`]}
                      validateMsg={Constant.OTHER_TITLE_REQUIRED}
                    />
                  )}
                </div>
            </div>
            <h3 className="h5 font-weight-header mt-3">
              {controlSecondaryTitle}
            </h3>
            {controlSecondaryText && (
              <p className="ml-3 mt-1">
                <small>{controlSecondaryText}</small>
              </p>
            )}
            <div className="row col-12 mt-2 ml-1">
              {primaryContactInfo && !isDataPreparer && (
                <p>
                  <small>
                    Provide the phone number and email address of the
                    organization’s Program Coordinator. DPRP staff will use this
                    information to communicate with the organization. All
                    DPRP-related documents, reports, and emails will go to the
                    Program Coordinator. The Program Coordinator’s contact
                    information will not be included in the DPRP registry or the
                    CDC Find a Program locator.
                  </small>
                </p>
              )}
              {!isDataPreparer && !primaryContactInfo && (
                <p>
                  <small>
                    Provide the phone number and email address of the
                    organization’s Secondary Contact, if applicable. The
                    Secondary Contact’s contact information will not be included
                    in the DPRP registry or the CDC Find a Program locator.
                  </small>
                </p>
              )}
              <TextInput
                onChange={onChange}
                onKeyDown={onKeyDown}
                onValidateBlur={onValidateBlur}
                isPhoneNumber={true}
                phoneNumberControl={phoneNumberControl}
                onBlurPhoneNumber={onBlurPhoneNumber}
                phoneNumberAreaCode={phoneNumberAreaCode}
                phoneNumber3={phoneNumber3}
                phoneNumber4={phoneNumber4}
                value={phoneNumber || ''}
                labelClassName=""
                labelText="Phone Number"
                extraText=""
                maxLength="16"
                name={phoneNumberControl}
                inputClassName=""
                containerClassName="ml-3"
                requiredLabel={true}
                error={errors && errors[`${phoneNumberControl}`]}
                validateMsg={Constant.CONTACT_PHONE_VALIDATE_MSG}
              />
              <TextInput
                onChange={onChange}
                value={PhoneExt || ''}
                labelClassName=""
                labelText="Ext"
                extraText=""
                name={PhoneExtControl}
                inputClassName=""
                containerClassName="ml-3 col-1"
              />
            </div>
            <div className="ml-3">
              <TextInput
                onChange={onChange}
                onValidateBlur={onValidateBlur}
                value={emailAddress || ''}
                labelClassName=""
                labelText="Email Address"
                extraText=""
                name={emailAddressControl}
                inputClassName=""
                containerClassName="ml-3 col-3 pl-0"
                maxLength="100"
                requiredLabel={true}
                error={errors && errors[`${emailAddressControl}`]}
                validateMsg={Constant.CONTACT_EMAIL_VALIDATE_MSG}
                errorClassName=""
              />
              <TextInput
                onChange={onChange}
                onValidateBlur={onValidateBlur}
                value={emailAddressVerify || ''}
                labelClassName=""
                labelText="Verify Email Address"
                extraText=""
                name={emailAddressVerifyControl}
                inputClassName=""
                containerClassName="ml-3 col-3 pl-0"
                maxLength="100"
                requiredLabel={true}
                error={errors && errors[`${emailAddressVerifyControl}`]}
                validateMsg={Constant.CONTACT_EMAIL_EMAIL_VERIFY_VALIDATE_MSG}
                errorClassName=""
              />
            </div>
          </div>
        )}
      </fieldset>
    </>
  );
}

export default ContactInfo;
