import React, { Component } from "react";
import { Collapse, Container, Navbar, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { getFormConfigInfo } from "../../api/api";

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  constructor(props) {
    super(props);
    this.state = {
      environment: ""
    };
  }
  componentDidMount() {
    getFormConfigInfo().then(({ data }) => {
      this.setState({
        environment: data.environment
      });
    });
  }
  render() {
    return (
        <Navbar
          role="navigation"
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          <Container>
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                {this.state.environment === "Development" && (
                  <>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/application"
                      >
                        Application
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/registry">
                        Registry
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
    );
  }
}
